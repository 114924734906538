import {legalRepresentations} from "../../defaultStates";
import {
    SET_EMPTY_LEGAL,
    SET_LEGAL_REPRESENTATION,
    SET_UPDATE_CARE_RECEIPIENTS,
    SET_UPDATE_LEGAL_REPRESENTATION
} from "../../ActionTypes";

export default (state = legalRepresentations , action = {}) => {
    switch (action.type) {
        case SET_LEGAL_REPRESENTATION:
            return {
                ...state,
                [action.key]: action.value,
            }
        case SET_UPDATE_LEGAL_REPRESENTATION:
            return {
                ...action.legalRepresentations
            }
        case SET_EMPTY_LEGAL:
            return {
                ...action.legal
            }
        default:
            return state

    }
}
