import {PHONE_NUMBER_FIELD_CHANGE} from '../../ActionTypes'
import { auth } from '../../defaultStates'

export default (state = auth, action = {}) => {
  switch (action.type) {
    case PHONE_NUMBER_FIELD_CHANGE:
      return {
        ...state,
        phoneNumberObj: action.phoneNumberObj,
      }
    default:
      return state
  }
}
