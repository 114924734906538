import React, {useState} from 'react'
import styles from "../modalComponent.module.scss";
import FlexiiLogo from "../../../../../icons/flexiiLogo.svg";
import {handlePhoneNumberFieldChange} from "../../../../../redux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";
import PhoneNumberField from "../../../../ReusableField/PhoneNumberField";
import Button from "../../../../UiKitComponents/Button";
import {closeConfirmationModal} from "../../../../../redux/actions/confirmationModal";
import {setEditFromSummary, setLegalRepresentation} from "../../../../../redux/actions/quizQuestions";
import {setLegalRepresentations} from "../../../../../redux/actions/careReceipients";

const LegalModalComponent = ({item, legalIndex}) => {
    console.log(item);
    const dispatch = useDispatch()
    const { phoneNumberObj } = useSelector((state) => state.auth)
    const [answer, setAnswer] = useState('')
    const { quizLegalRepresentation:{quizLegal,indexLegal} } = useSelector((state) => state.quizQuestions)
    const { legalRepresentations, payment:{reimbursementRef} } = useSelector((state) => state)
    const handleChangePhone = (newPhoneNumberObj) => {
        dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
    }
    const handleLegalAnswer = (index) => {
        if(!answer) return
        const newQuiz= quizLegal.map((item , i)=> {
            if(i === index){
                return {
                    ...item,
                    answers: answer,
                }
            }
            return item
        })
        dispatch(setLegalRepresentations(Object.keys(legalRepresentations)[index],answer))
        dispatch(setLegalRepresentation(newQuiz,indexLegal))
        dispatch(handlePhoneNumberFieldChange({...phoneNumberObj,number:''}))
        setAnswer('')
    }
    return (
        <section className={styles.modalComponent}>
            <p className={styles.odd}>
                <img src={item?.img && FlexiiLogo} alt={FlexiiLogo}/>
                <p>
                    {item.question}
                </p>
            </p>
            {item.type === 'phoneNumber' && <PhoneNumberField
                onChange={handleChangePhone}
                phoneNumberObj={phoneNumberObj}
                setAnswerr={setAnswer}
                value={item.answers}
            />}
            <div>
                <Button  small primaryOutlined onClick={() => {
                    dispatch(closeConfirmationModal())
                    dispatch(setEditFromSummary(''))
                } }>
                    {'Abbrechen'}
                </Button>
                <Button
                    primary
                    small
                    onClick={() => {
                        handleLegalAnswer(legalIndex)
                        dispatch(closeConfirmationModal())
                        dispatch(setEditFromSummary(''))
                    }}
                >
                    {'Speichern'}
                </Button>
            </div>
        </section>
    )
}
export default LegalModalComponent
