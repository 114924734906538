
import {
    authorizedQuestions,
    legalQuestions,
    ListEntryRepresentationByCommerciallyActivePerson, listEntryRepresentationByPrivateIndividual,
    questions,
    representativeQuestions
} from "../constants/quizConstant";

export const metadata = {
    selectedLanguage: 'de',
}
export const userDetails = {
    email:''
}
export const startChallengeSteps = {
    // enterEmail: { registrationStep: 0 },
    startChallenge: { challengeStep: 0 }
}
export const quizQuestions = {
    quizCareReceipients: {
        quizCare:questions,
        indexCare:0
    },
    quizLegalRepresentation: {
        quizLegal:legalQuestions,
        indexLegal:0
    },
    quizAuthorizedPerson: {
        quizAuth:authorizedQuestions,
        indexAuth:0
    },
    quizRepresentativePerson: {
        quizRep: representativeQuestions,
        indexRep: 0
    },
    commerciallyActive: {
        quizActive:ListEntryRepresentationByCommerciallyActivePerson,
        indexActive:0
    },
    privateIndividual: {
        quizPrivate:listEntryRepresentationByPrivateIndividual,
        indexPrivate:0
    },
    questionGroupStep: 1,
    chooseCard:'',
    editSummary: '',
    formEdit: false,
}
export const careReceipients = {
    name: '',
    lastName: '',
    birthday: '',
    address: '',
    houseNumber: '',
    zipCode : '',
    city : '',
    phoneNumber: '',
    insurance: '',
    insuranceNumber: '',
    levelOfCare: '',
    certificate: '',
    legalRepresentation: ''
}
export const legalRepresentations = {
    name: '',
    lastName: '',
    address: '',
    houseNumber: '',
    zipCode : '',
    city : '',
    phoneNumber: '',
    document: '',
}
export const authorizedPerson = {
    name: '',
    lastName: '',
    birthday: '',
    address: '',
    houseNumber: '',
    zipCode : '',
    city : '',
    phoneNumber: '',
    professionalCarer: '',
    periodOfCare: '',
    periodOfAbsenceFrom: '',
    periodOfAbsenceTo: '',
    reasonForTheAbsence : '',
    durationOfAbsence : '',
}
export const representativePerson = {
    name: '',
    lastName: '',
    address: '',
    houseNumber: '',
    zipCode : '',
    city : '',
    phoneNumber: '',
    personRepresenting:'',
    commerciallyActive: '',
    relationshipList:'',
    relationshipByMarriage:'',
    sameHousehold:'',
    relationship:''
}
export const representationByCommerciallyActive = {
    respiteCare1Date: '',
    respiteCare1Time: '',
    respiteCare1Duration: '',
    respiteCare1Remuneration: '',
}
export const representationByPrivateIndividual = {
    respiteCare1Date: '',
    respiteCare1Time: '',
    respiteCare1Duration: '',
    respiteCare1Remuneration: '',
    representingPersonByTransport:'',
    representingPersonByCar:'',
    zipCodeFrom:'',
    zipCodeTo:'',
    NameOfAccountHolder:'',
    IBAN:'',
    bank:'',
    ConsentToTheData:'',
    date:'',
    iAgree:'',
    iHave:'',
}
export const auth = {
    phoneNumberObj: {
        countryCode: 'DE',
        callingCode: '49',
        number: '',
    },
}
export const validation = {
    signInWithEmailFormValidation: {},
    nameFormValidation: {},
    passwordFormValidation: {}
}
export const defaultStore = {
    metadata,
    userDetails,
    startChallengeSteps,
    quizQuestions,
    confirmationModal: null,
    careReceipients,
    legalRepresentations,
    authorizedPerson,
    representativePerson,
    representationByCommerciallyActive,
    representationByPrivateIndividual,
    validation,
    auth
}
