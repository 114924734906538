import { SET_REIMBURSEMENTREF, } from '../../ActionTypes'

export default (state = {}, action = {}) => {
    switch (action.type) {
        case SET_REIMBURSEMENTREF:
            return {
                ...state,
                reimbursementRef: action.reimbursementRef,
            }
        default:
            return state
    }
}