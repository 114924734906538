import styles from "./modalFooter.module.scss"
// import Button from "../../../UiKitComponents/Button"
import React from "react"
import Button from "../../../UiKitComponents/Button";

export default ({
  handleConfirm,
  handleCancel,
  confirmDisabled,
  confirm,
  cancel,
}) => {
  const { text: cancelText, ...cancelRest } = cancel
  const { text: confirmText, ...confirmRest } = confirm

  return (
    <footer className={styles.footerStyles}>
      {!!cancelText && (
        <Button  small primaryOutlined onClick={() => handleCancel()} {...cancelRest}>
          {cancelText}
        </Button>
      )}
      {!!confirmText && (
        <Button
          primary
          small
          onClick={handleConfirm}
          disabled={confirmDisabled}
          {...confirmRest}
        >
          {confirmText}
        </Button>
      )}
    </footer>
  )
}
