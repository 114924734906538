import React, {useEffect, useState} from "react";
import styles from './summaryDetails.module.scss'
import Details from "../Details";
import Robotik from '../../../../icons/robbot.svg'
import {getAuthUserPdf, getPdf} from '../../../../api/formApi';

import {getReimbursements} from "../../../../api/userApi";
import {
    setUpdateAuthorizedPerson,
    setUpdateCareQuizQuestions,
    setUpdateLegalRepresentations,
    setUpdateRepresentationByCommerciallyActive,
    setUpdateRepresentationByPrivateIndividual,
    setUpdateRepresentativePerson,
} from "../../../../redux/actions/updateState";
import {useDispatch, useSelector} from "react-redux";

import { getData} from "../../../../utils/helpers/isProduction";
import { setToastNotification } from '../../../../redux/actions/toastNotification';
const SummaryDetails = () => {
    const dispatch = useDispatch()
    const ref = useSelector((state) => state.payment).reimbursementRef
    const [careFileName, setcareFileName] = useState('')
    const [legalFileName, setlLegalFileName] = useState('')
    const accessToken = localStorage.getItem('accessToken')
    const handleGetPdf = () => {
        const body = { ref }
        accessToken ?  getAuthUserPdf(body)
            .then(() => {
                dispatch(
                    setToastNotification({
                        type: 'success',
                        toastTittle: 'Successfully sent',
                        toastBody: 'Interactively monetize corporate alignments and fully tested niche markets.',
                    })
                )
            })
            .catch((err) => console.log(err)) : getPdf(body).then(() => {
            dispatch(
                setToastNotification({
                    type: 'success',
                    toastTittle: 'Successfully sent',
                    toastBody: 'Interactively monetize corporate alignments and fully tested niche markets.',
                })
            )
        }).catch((err) => console.log(err))
    }
    const {
        quizQuestions
    } = useSelector((state) => state)
    useEffect(() => {
        getReimbursements(ref).then((data)=>{
            console.log(data.params.careRecipients?.birthday);

            dispatch(setUpdateCareQuizQuestions({
                name: data.params.careRecipients?.name,
                lastName: data.params.careRecipients?.lastName,
                birthday: data.params.careRecipients?.birthday,
                address: data.params.careRecipients?.address,
                houseNumber: data.params.careRecipients?.houseNumber,
                zipCode: data.params.careRecipients?.zipCode,
                city: data.params.careRecipients?.city,
                phoneNumber: data.params.careRecipients?.phoneNumber,
                insurance: data.params.careRecipients?.insurance,
                insuranceNumber: data.params.careRecipients?.insuranceNumber,
                levelOfCare: data.params.careRecipients?.levelOfCare,
                certificate: data.params.careRecipients?.certificate,
                legalRepresentation: data.params.careRecipients?.legalRepresentation
            }))
            dispatch(setUpdateLegalRepresentations({
                name: data.params.legalRepresentations?.name,
                lastName: data.params.legalRepresentations?.lastName,
                address: data.params.legalRepresentations?.address,
                houseNumber: data.params.legalRepresentations?.houseNumber,
                zipCode : data.params.legalRepresentations?.zipCode,
                city : data.params.legalRepresentations?.city,
                phoneNumber: data.params.legalRepresentations?.phoneNumber,
                document: data.params.legalRepresentations?.document,
            }))
            dispatch(setUpdateAuthorizedPerson({
                name: data.params.authorizedPerson?.name,
                lastName: data.params.authorizedPerson?.lastName,
                birthday: data.params.authorizedPerson?.birthday,
                address: data.params.authorizedPerson?.address,
                houseNumber: data.params.authorizedPerson?.houseNumber,
                zipCode : data.params.authorizedPerson?.zipCode,
                city : data.params.authorizedPerson?.city,
                phoneNumber: data.params.authorizedPerson?.phoneNumber,
                professionalCarer: data.params.authorizedPerson?.professionalCarer,
                periodOfCare: data.params.authorizedPerson?.periodOfCare,
                periodOfAbsenceFrom: data.params.authorizedPerson?.periodOfAbsenceFrom,
                periodOfAbsenceTo: data.params.authorizedPerson?.periodOfAbsenceTo,
                reasonForTheAbsence : data.params.authorizedPerson?.reasonForTheAbsence,
                durationOfAbsence : data.params.authorizedPerson?.durationOfAbsence,
            }))
            dispatch(setUpdateRepresentativePerson({
                name: data.params.representativePerson?.name,
                lastName: data.params.representativePerson?.lastName,
                address: data.params.representativePerson?.address,
                houseNumber: data.params.representativePerson?.houseNumber,
                zipCode : data.params.representativePerson?.zipCode,
                city : data.params.representativePerson?.city,
                phoneNumber: data.params.representativePerson?.phoneNumber,
                personRepresenting:data.params.representativePerson?.personRepresenting,
                commerciallyActive: data.params.representativePerson?.commerciallyActive,
                relationshipList:data.params.representativePerson?.relationshipList,
                relationshipByMarriage:data.params.representativePerson?.relationshipByMarriage,
                sameHousehold:data.params.representativePerson?.sameHousehold,
                relationship:data.params.representativePerson?.relationship

            }))
            dispatch(setUpdateRepresentationByCommerciallyActive({
                respiteCare1Date: data.params.representationByCommerciallyActive?.respiteCare1Date,
                respiteCare1Time: data.params.representationByCommerciallyActive?.respiteCare1Time,
                respiteCare1Duration: data.params.representationByCommerciallyActive?.respiteCare1Duration,
                respiteCare1Remuneration: data.params.representationByCommerciallyActive?.respiteCare1Remuneration,
            }))
            dispatch(setUpdateRepresentationByPrivateIndividual({
                respiteCare1Date: data.params.representationByPrivateIndividual?.respiteCare1Date,
                respiteCare1Time: data.params.representationByPrivateIndividual?.respiteCare1Time,
                respiteCare1Duration: data.params.representationByPrivateIndividual?.respiteCare1Duration,
                respiteCare1Remuneration: data.params.representationByPrivateIndividual?.respiteCare1Remuneration,
                representingPersonByTransport:data.params.representationByPrivateIndividual?.representingPersonByTransport,
                representingPersonByCar:data.params.representationByPrivateIndividual?.representingPersonByCar,
                zipCodeFrom:data.params.representationByPrivateIndividual?.zipCodeFrom,
                zipCodeTo:data.params.representationByPrivateIndividual?.zipCodeTo,
                NameOfAccountHolder:data.params.representationByPrivateIndividual?.NameOfAccountHolder,
                IBAN:data.params.representationByPrivateIndividual?.IBAN,
                bank:data.params.representationByPrivateIndividual?.bank,
                ConsentToTheData:data.params.representationByPrivateIndividual?.ConsentToTheData,
                date:data.params.representationByPrivateIndividual?.date,
                iAgree:data.params.representationByPrivateIndividual?.iAgree,
                iHave:data.params.representationByPrivateIndividual?.iHave,
            }))
           const x = Object.values(quizQuestions).map((item)=>{
               if(typeof item === 'object'){
                   let action = ''
                   const arr =  Object.values(item)[0]?.map((el)=> {
                       action = getData(Object.keys(item)[0], data.params, el.questionKey).action
                       return {
                           ...el,
                           answers: getData(Object.keys(item)[0], data.params, el.questionKey).answers,
                           fileName: getData(Object.keys(item)[0], data.params, el.questionKey, data, careFileName,legalFileName, setlLegalFileName, setcareFileName ).fileName
                       }
                   })
                   action && dispatch(action(arr, arr?.length))
                   return arr
               }

            })
        })
    }, [careFileName,legalFileName]);
    return (
        <div className={styles.summary}>
            <Details/>
            <div className={styles.getPdf}>
                <div>
                    <img src={Robotik} alt={Robotik}/>
                </div>
                <div>
                    <div>
                        <p>Hier können Sie IhrFormular überprüfen.</p>
                        <button onClick={() => handleGetPdf()}>PDF herunterladen</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SummaryDetails

