import {representationByPrivateIndividual} from "../../defaultStates";
import {
    SET_EMPTY_PRIVATE,
    SET_PRIVATE_INDIVIDUAL, SET_UPDATE_COMMERCIALLY_ACTIVE, SET_UPDATE_PRIVATE_INDIVIDUAL
} from "../../ActionTypes";

export default (state = representationByPrivateIndividual , action = {}) => {
    switch (action.type) {
        case SET_PRIVATE_INDIVIDUAL:
            return {
                ...state,
                [action.key]: action.value,
            }
        case SET_UPDATE_PRIVATE_INDIVIDUAL:
            return {
                ...action.representationByPrivateIndividual
            }
        case SET_EMPTY_PRIVATE:
            return {
                ...action.privat
            }
        default:
            return state
    }
}
