import React from 'react'
import errorIcon from '../../../icons/errorIcon.svg'
import styles from './errorMessageComponent.module.scss'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

const ErrorMessageComponent = ({ errorMessage, customFooter }) => {
  const { t } = useTranslation()
  return (
    <div
      className={cx(styles.errorMessage, {
        [styles.customError]: !customFooter,
      })}
    >
      <img src={errorIcon} alt={'errorIcon'} />
      <span>{t(errorMessage)}</span>
    </div>
  )
}
export default ErrorMessageComponent
