import { SET_TOAST_NOTIFICATION, CLOSE_TOAST_NOTIFICATION } from '../../ActionTypes'

export default (state = null, action = {}) => {
    switch (action.type) {
        case SET_TOAST_NOTIFICATION:
            return {
                ...action.notificationProps,
            }
        case CLOSE_TOAST_NOTIFICATION:
            return null
        default:
            return state
    }
}
