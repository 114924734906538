import {
    SET_UPDATE_ACTIVE_QUIZ, SET_UPDATE_AUTH_QUIZ,
    SET_UPDATE_AUTHORIZED_PERSON,
    SET_UPDATE_CARE_QUIZ,
    SET_UPDATE_CARE_RECEIPIENTS,
    SET_UPDATE_COMMERCIALLY_ACTIVE, SET_UPDATE_LEGAL_QUIZ,
    SET_UPDATE_LEGAL_REPRESENTATION,
    SET_UPDATE_PRIVATE_INDIVIDUAL,
    SET_UPDATE_PRIVATE_QUIZ, SET_UPDATE_REP_QUIZ,
    SET_UPDATE_REPRESENTATIVE_PERSON
} from "../../ActionTypes";



export const setUpdateCareQuizQuestions = (careReceipients) => ({
    type: SET_UPDATE_CARE_RECEIPIENTS,
    careReceipients
})

export const setUpdateLegalRepresentations = (legalRepresentations)=>({
    type: SET_UPDATE_LEGAL_REPRESENTATION,
    legalRepresentations
})
export const setUpdateAuthorizedPerson = (authorizedPerson) => ({
    type:SET_UPDATE_AUTHORIZED_PERSON,
    authorizedPerson
})
export const setUpdateRepresentativePerson = (representativePerson)=>({
    type: SET_UPDATE_REPRESENTATIVE_PERSON,
    representativePerson
})

export const setUpdateRepresentationByCommerciallyActive = (representationByCommerciallyActive) => ({
    type: SET_UPDATE_COMMERCIALLY_ACTIVE,
    representationByCommerciallyActive
})

export const setUpdateRepresentationByPrivateIndividual = (representationByPrivateIndividual) => ({
    type: SET_UPDATE_PRIVATE_INDIVIDUAL,
    representationByPrivateIndividual
})

export const updateCareQuiz = (care,index) => ({
    type: SET_UPDATE_CARE_QUIZ,
    care,
    index
})
export const updateLegalQuiz = (legal,index) => ({
    type: SET_UPDATE_LEGAL_QUIZ,
    legal,
    index
})
export const updateAuthQuiz = (auth,index) => ({
    type: SET_UPDATE_AUTH_QUIZ,
    auth,
    index
})
export const updateRepQuiz = (rep,index) => ({
    type: SET_UPDATE_REP_QUIZ,
    rep,
    index
})
export const updateActiveQuiz = (active,index) => ({
    type: SET_UPDATE_ACTIVE_QUIZ,
    active,
    index
})
export const updatePrivateQuiz = (privat,index) => ({
    type: SET_UPDATE_PRIVATE_QUIZ,
    privat,
    index
})
