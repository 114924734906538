import React from 'react'
import styles from './button.module.scss'
import cx from 'classnames'

const Button = ({
  children,
  primary,
  text,
  medium,
  large,
  disabled,
  oval,
  gray,
  largePrimary,
  grayCancelText,
  grayConfirm,
  smallPrimaryBg,
  smallPrimaryNotBg,
  largeGray,
  warningOutlined,
  primaryOutlined,
  customStyles,
  warning,
  redText,
  largePrimaryDisabled,
  promoCodeApplied,
  apply,
  ...props
}) => {
  return (
    <button
      disabled={disabled}
      className={cx(customStyles, styles.button, {
        [styles.primary]: primary,
        [styles.largePrimary]: largePrimary,
        [styles.grayCancelText]: grayCancelText,
        [styles.grayConfirm]: grayConfirm,
        [styles.smallPrimaryBg]: smallPrimaryBg,
        [styles.smallPrimaryNotBg]: smallPrimaryNotBg,
        [styles.largeGray]: largeGray,
        [styles.text]: text,
        [styles.large]: large,
        [styles.disabled]: disabled && !largePrimaryDisabled && !promoCodeApplied,
        [styles.largePrimaryDisabled]: disabled && largePrimaryDisabled,
        [styles.applyDisabled]: disabled && promoCodeApplied,
        [styles.apply]: apply,
        [styles.gray]: gray,
        [styles.medium]: medium,
        [styles.oval]: oval,
        [styles.warningOutlined]: warningOutlined,
        [styles.primaryOutlined]: primaryOutlined,
        [styles.warning]: warning,
        [styles.redText]: redText,
      })}
      {...props}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
