import { metadata } from '../../defaultStates'
import {
  SET_SELECTED_LANGUAGE,
} from '../../ActionTypes'

export default (state = metadata, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.selectedLanguage,
      }
    default:
      return state
  }
}
