import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

const Modal = ({ children }) => {
  const modalNode = document.getElementById('modal')

  useEffect(() => {
    document.body.classList.add('overflowHidden')
    window.scrollTo(0, 0)

    return () => document.body.classList.remove('overflowHidden')
  }, [])

  return ReactDOM.createPortal(children, modalNode)
}

export default Modal
