import { SET_USER_EMAIL, USER_LOGOUT, SET_USER_DETAILS } from "../../ActionTypes";

export const setUserEmail = (email) => ({
    type: SET_USER_EMAIL,
    email,
})

export const logOutUser = () => ({
    type: USER_LOGOUT,
})

export const setUserDetails = (data) => ({
    type: SET_USER_DETAILS,
    data,
})
