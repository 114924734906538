import {representationByCommerciallyActive} from "../../defaultStates";
import {
    SET_COMMERCIALLY_ACTIVE, SET_EMPTY_ACTIVE, SET_UPDATE_COMMERCIALLY_ACTIVE, SET_UPDATE_LEGAL_REPRESENTATION
} from "../../ActionTypes";

export default (state = representationByCommerciallyActive , action = {}) => {
    switch (action.type) {
        case SET_COMMERCIALLY_ACTIVE:
            return {
                ...state,
                [action.key]: action.value,
            }
        case SET_UPDATE_COMMERCIALLY_ACTIVE:
            return {
                ...action.representationByCommerciallyActive
            }
        case SET_EMPTY_ACTIVE:
            return {
                ...action.active
            }
        default:
            return state
    }
}
