import React, {useEffect, useRef, useState} from 'react'
import styles from './challengeSteps.module.scss'
import Robotik from '../../../../icons/robbot.svg'
import cx from 'classnames'
import {finalQuestions} from "../../../../constants/quizConstant";
import {useDispatch, useSelector} from "react-redux";
import {
    setAuthPerson,
    setCareQuizQuestions, setCommerciallyActive, setEditFromSummary,
    setLegalRepresentation, setPrivateIndividual, setRepresentativePerson
} from '../../../../redux/actions/quizQuestions';
import {
    setAuthorizedPerson,
    setCareReceipients, setCommercially, setIndividual,
    setLegalRepresentations,
    setRepresentative
} from "../../../../redux/actions/careReceipients";
import Details from "../Details";
import CareChallenge from "../CareChallenge";
import LegalChallenge from "../LegalChallenge";
import AuthChallenge from "../AuthChallenge";
import RepresentativeChallenge from "../RepresentativeChallenge";
import CommerciallyActiveChallenge from "../CommerciallyActiveChallenge";
import PageComponent from "../../../Pages/PageComponent";
import PrivateIndividualChallenge from "../PrivateIndividualChallenge";
const ChallengeSteps = () => {
    const dispatch = useDispatch()
    const { pathname } = window.location
    const { quizCareReceipients:{ quizCare,indexCare},
        quizLegalRepresentation:{quizLegal,indexLegal},
        quizAuthorizedPerson:{quizAuth,indexAuth},
        quizRepresentativePerson:{quizRep,indexRep},
        commerciallyActive:{quizActive,indexActive},
        privateIndividual:{quizPrivate,indexPrivate},
        editSummary,
        index, groupName
    } = useSelector((state) => state.quizQuestions)
    const {
        careReceipients,
        legalRepresentations,
        authorizedPerson,
        representativePerson,
        representationByCommerciallyActive,
        representationByPrivateIndividual,
    } = useSelector((state) => state)

    const bottomOfPanelRef = useRef(null)
    const [edit, setEdit] = useState(-1)
    const [editList, setEditList] = useState(-1)
    const [textAreaValue, setTextAreaValue] = useState('')
    const [windowWidth, setWindowWidth] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const isDisabledCare = quizCare.some((item, i) => i === indexCare && item.required === true)
    const isDisabledLegal = quizLegal.some((item, i) => i === indexLegal && item.required === true)
    const isDisabledAuth = quizAuth.some((item, i) => i === indexAuth && item.required === true)
    const isDisabledRep = quizRep.some((item, i) => i === indexRep && item.required === true)
    const isDisabledActive = quizActive.some((item, i) => i === indexActive && item.required === true)
    const isDisabledPrivate = quizPrivate.some((item, i) => i === indexPrivate && item.required === true)
    function findFirstUnansweredIndex(quizArray) {
        const questionIndex = quizArray?.findIndex((item) => item?.answers === '' || item.answers === '0' || item.answers === '1' ||
           (item.question === 'Ist die verhinderte Pflegeperson gewerblich als Pfleger tätig oder sogar eine Pflegefirma ?' &&
               item.answers === 'Ja') || item.answers === 'Unter 6 monate' || item.answers === 'nicht verwandt' ||
            item.answers === 'Nicht verschwägert' );
        return questionIndex >= 0 ? questionIndex : quizArray?.length;
    }
    const notCareAnsweredIndex = findFirstUnansweredIndex(quizCare);
    const notLegalAnsweredIndex = findFirstUnansweredIndex(quizLegal);
    const notAuthAnsweredIndex = findFirstUnansweredIndex(quizAuth);
    const notRepAnsweredIndex = findFirstUnansweredIndex(quizRep);
    const notActiveAnsweredIndex = findFirstUnansweredIndex(quizActive);
    const notPrivateAnsweredIndex = findFirstUnansweredIndex(quizPrivate);

    let Active = Number(representationByCommerciallyActive.respiteCare2Remuneration) + Number(representationByCommerciallyActive.respiteCare1Remuneration)
    let Private = Number(representationByPrivateIndividual.respiteCare2Remuneration) + Number(representationByPrivateIndividual.respiteCare1Remuneration)
    const messageRefs = useRef({});
    const [robbotikMessage, setRobbotikMessage] = useState(false)
    const handleResize = () => {
        setWindowWidth({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        if (messageRefs.current[`${groupName}${index}`] && editSummary) {
            messageRefs.current[`${groupName}${index}`].scrollIntoView(true);
        }
    }, []);

    useEffect(() => {
    }, [careReceipients,
        legalRepresentations,
        authorizedPerson,
        representativePerson,
        representationByCommerciallyActive,
        representationByPrivateIndividual
    ]);

    useEffect(() => {
        if(bottomOfPanelRef.current && !editSummary){
            bottomOfPanelRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
        }

    }, [quizCare, quizLegal,quizAuth,quizRep,quizActive,quizPrivate]);


const handleEditText = (index, quizType,setFunction, indexType) => {
    const newQuiz = quizType.map((item, i) => {
      if (i === index) {
          dispatch(setEditFromSummary(item.answers))
            return {
                ...item,
                edit: true
            };
        }
        return item;
    });
    dispatch(setFunction(newQuiz, indexType));
}
const handleEditCare = (index) => {
    handleEditText(index, quizCare, setCareQuizQuestions,indexCare);
};
    const handleEditeLegal = (index) => {
        handleEditText(index, quizLegal, setLegalRepresentation,indexLegal);
    };

    const handleEditAuth = (index) => {
        handleEditText(index, quizAuth, setAuthPerson,indexAuth);
    };

    const handleEditRep = (index) => {
        handleEditText(index, quizRep, setRepresentativePerson, indexRep);
    };

    const handleEditActive= (index) => {
        handleEditText(index, quizActive, setCommerciallyActive,indexActive);
    };

    const handleEditPrivate = (index) => {
        handleEditText(index, quizPrivate, setPrivateIndividual,indexPrivate);
    };
const getTextValue = (e) => {
   if(e.currentTarget.value === '') {
       setTextAreaValue('-')
   }else{
       setTextAreaValue(e.currentTarget.value)
   }
}
    const handleCancel = (index, quizType, setFunction,indexType) => {
        const newQuiz = quizType.map((item, i) => {
            if (i === index) {
                dispatch(setEditFromSummary(''))

                return {
                    ...item,
                    edit: false
                };
            }
            return item;
        });
        dispatch(setFunction(newQuiz, indexType));
    }
    const handleCancelCare = (index) => {
        handleCancel(index, quizCare, setCareQuizQuestions,indexCare);
    };
    const handleCancelLegal = (index) => {
        handleCancel(index, quizLegal, setLegalRepresentation,indexLegal);
    };

    const handleCancelAuth = (index) => {
        handleCancel(index, quizAuth, setAuthPerson,indexAuth);
    };

    const handleCancelRep = (index) => {
        handleCancel(index, quizRep, setRepresentativePerson, indexRep);
    };

    const handleCancelActive= (index) => {
        handleCancel(index, quizActive, setCommerciallyActive,indexActive);
    };

    const handleCancelPrivate = (index) => {
        handleCancel(index, quizPrivate, setPrivateIndividual,indexPrivate);
    };
    const handleTextAnswer = (index, quizType, setFunction, setAnswersFunction, keyObject, indexType,  returnQuestionLogic = null) => {
        const newQuiz = quizType.map((item, i) => {
            console.log(quizType[index]);
            if (!textAreaValue && i === index) {
                dispatch(setEditFromSummary(''))
                return {
                    ...item,
                    answers: '-',
                    edit: false
                };
            } else if (i === index) {
                if(!Number(textAreaValue) && quizType[index].type === 'number'){
                    setRobbotikMessage(true)
                }
                dispatch(setEditFromSummary(''))
                return {
                    ...item,
                    answers: textAreaValue,
                    returnQuestion: textAreaValue === '0' || textAreaValue === '1' ?
                        'Ersatz von Kosten der Verhinderungspflege ist derzeit nicht möglich, mindestens Pflegegrad 2 ist erforderlich.':
                        '',
                    edit: false
                };
            }
            return item;
        });

        dispatch(setAnswersFunction(Object.keys(keyObject)[index], !textAreaValue ? '-' : textAreaValue));
        dispatch(setFunction(newQuiz, indexType));
        setTextAreaValue('');
    };

    const handleCareTextAnswer = (index) => {
        handleTextAnswer(index, quizCare, setCareQuizQuestions, setCareReceipients, careReceipients, indexCare);
    };

    const handleLegalTextAnswer = (index) => {
        handleTextAnswer(index, quizLegal, setLegalRepresentation, setLegalRepresentations, legalRepresentations, indexLegal);
    };

    const handleAuthTextAnswer = (index) => {
        const authReturnQuestionLogic = (value) => value === 'Ja' ?
            'Reimbursement of costs for respite care is not possible for commercial carers and care companies' :
            '';
        handleTextAnswer(index, quizAuth, setAuthPerson, setAuthorizedPerson, authorizedPerson, indexAuth, authReturnQuestionLogic);
    };

    const handleRepTextAnswer = (index) => {
        const repReturnQuestionLogic = (value) => {
            if (value === 'Nein') {
                return 'Vertretende Person ist privat und nur gelegentlich als PflegerIn tätig.';
            }
            if (value !== 'nicht verwandt') {
                return 'Ersatz von Kosten der Verhinderungspflege ist bei Personen in Verwandschaft, Lebensgemeinschaft und Ehepartnern nur eingeschränkt möglich.';
            }
            if (value !== 'Nicht verschwägert') {
                return 'Ersatz von Kosten der Verhinderungspflege ist bei Verschwägerten Personen nur eingeschränkt möglich.';
            }
            if (value === 'Ja') {
                return 'Ersatz von Kosten der Verhinderungspflege ist bei dauernd im gleichen Haushalt lebenden Personen nur eingeschränkt möglich.';
            }
            return '';
        };
        handleTextAnswer(index, quizRep, setRepresentativePerson, setRepresentative, representativePerson, indexRep, repReturnQuestionLogic);
    };

    const handleActiveTextAnswer = (index) => {
        handleTextAnswer(index, quizActive, setCommerciallyActive, setCommercially, representationByCommerciallyActive, indexActive);
    };

    const handlePrivateTextAnswer = (index) => {
        handleTextAnswer(index, quizPrivate, setPrivateIndividual, setIndividual, representativePerson, indexPrivate);
    };
    const handleSkip = () => {
        if(quizCare.length !== indexCare) {
            const newCareQuiz= quizCare.map((item , i)=> {
                if(i === indexCare){
                    return {
                        ...item,
                        answers: '-',
                        fileName: '-'
                    }

                }
                return item
            })
            dispatch(setCareReceipients(Object.keys(careReceipients)[indexCare],'-'))
            dispatch(setCareQuizQuestions(newCareQuiz,indexCare+1))
        }else{
            if(careReceipients.legalRepresentation === 'Ja' && quizLegal.length !== indexLegal){
                const newLegalQuiz = quizLegal.map((item , i)=> {
                    if(i === indexLegal){
                        return {
                            ...item,
                            answers: '-',
                            fileName: '-'

                        }
                    }
                    return item
                })
                dispatch(setLegalRepresentations(Object.keys(legalRepresentations)[indexLegal],'-'))
                dispatch(setLegalRepresentation(newLegalQuiz,indexLegal+1))
            }else{
                if(quizAuth.length !== indexAuth ){
                    const newAuthQuiz = quizAuth.map((item , i)=> {
                        if(i === indexAuth){
                            return {
                                ...item,
                                answers: '-'
                            }
                        }
                        return item
                    })
                    dispatch(setAuthorizedPerson(Object.keys(authorizedPerson)[indexAuth],'-'))
                    dispatch(setAuthPerson(newAuthQuiz,indexAuth+1))
                }else {
                    if(quizRep.length !== indexRep) {
                        const newRepQuiz = quizRep.map((item , i)=> {
                            if(i === indexRep){
                                return {
                                    ...item,
                                    answers: '-'
                                }
                            }
                            return item
                        })
                        dispatch(setRepresentative(Object.keys(representativePerson)[indexRep],'-'))
                        dispatch(setRepresentativePerson(newRepQuiz,indexRep+1))
                    }else{
                        if((representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') && quizActive.length !== indexActive) {
                            const newActiveQuiz = quizActive.map((item , i)=> {
                                if(i === indexActive){
                                    return {
                                        ...item,
                                        answers: '-'
                                    }
                                }
                                return item
                            })
                            dispatch(setCommercially(Object.keys(representationByCommerciallyActive)[indexActive],'-'))
                            dispatch(setCommerciallyActive(newActiveQuiz,indexActive+1))
                        }else{
                            if(((representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Ja' && representativePerson.relationship) ||
                                (representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Nein'&& representativePerson.relationship) ||
                                (representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Nein' && representativePerson.relationship) || (
                                    representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja' &&
                                    representationByCommerciallyActive.respiteCare1Remuneration
                                )
                            ) && quizPrivate.length !== indexPrivate) {
                                const newPrivateQuiz = quizPrivate.map((item , i)=> {
                                    if(i === indexPrivate){
                                        return {
                                            ...item,
                                            answers: '-'
                                        }
                                    }
                                    return item
                                })
                                dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[indexPrivate],'-'))
                                dispatch(setPrivateIndividual(newPrivateQuiz,indexPrivate + 1))
                            }
                        }
                    }
                }
            }
        }


    }
    return(
        <PageComponent>
        <div className={cx(styles.challengeSteps)}>
           <Details notCareAnsweredIndex={notCareAnsweredIndex}/>
            <div>
                <div>
                    Hallo, mein Name ist FLEXX-i und ich freue mich, Ihnen zu helfen!
                    Lassen Sie uns mit der Erstellung Ihres Antrags auf Verhinderungspflege beginnen. Beantworten Sie mir bitte folgende Fragen:
                </div>
                <div>
                    <ul>
                    <CareChallenge
                        careReceipients={careReceipients}
                        indexCare={indexCare}
                        quizCare={quizCare}
                        notCareAnsweredIndex={notCareAnsweredIndex}
                        editList={editList}
                        setEdit={setEdit}
                        getTextValue={getTextValue}
                        handleCareTextAnswer={handleCareTextAnswer}
                        edit={edit}
                        handleCancel={handleCancelCare}
                        handleEdit={handleEditCare}
                        messageRefs={messageRefs}
                        robbotikMessage={robbotikMessage}
                        setRobbotikMessage={setRobbotikMessage}
                    />
                    {(careReceipients.levelOfCare !== '0' || careReceipients.levelOfCare !== '1') && (careReceipients.legalRepresentation === 'Ja'  && quizCare.length === indexCare ) &&
                        <LegalChallenge
                            quizLegal={quizLegal}
                            indexLegal={indexLegal}
                            legalRepresentations={legalRepresentations}
                            notLegalAnsweredIndex={notLegalAnsweredIndex}
                            editList={editList}
                            setEdit={setEdit}
                            getTextValue={getTextValue}
                            handleLegalTextAnswer={handleLegalTextAnswer}
                            edit={edit}
                            handleEdit={handleEditeLegal}
                            handleCancel={handleCancelLegal}
                            messageRefs={messageRefs}
                            robbotikMessage={robbotikMessage}
                            setRobbotikMessage={setRobbotikMessage}
                        />
                    }
                    {(
                        ((careReceipients.levelOfCare === '0' && careReceipients.levelOfCare === '1') &&
                               ((careReceipients.legalRepresentation === 'Ja' && quizLegal.length !== indexLegal)
                            || (careReceipients.legalRepresentation === 'Nein' && quizCare.length !== indexCare)))

                            ||

                        ((careReceipients.levelOfCare !== '0' && careReceipients.levelOfCare !== '1') &&
                            ((careReceipients.legalRepresentation === 'Ja' && quizLegal.length === indexLegal)
                                || (careReceipients.legalRepresentation === 'Nein' && quizCare.length === indexCare)))
                        )
                        &&
                        <AuthChallenge
                            quizAuth={quizAuth}
                            notAuthAnsweredIndex={notAuthAnsweredIndex}
                            editList={editList}
                            setEdit={setEdit}
                            getTextValue={getTextValue}
                            handleAuthTextAnswer={handleAuthTextAnswer}
                            edit={edit}
                            handleEdit={handleEditAuth}
                            handleCancel={handleCancelAuth}
                            messageRefs={messageRefs}
                            robbotikMessage={robbotikMessage}
                            setRobbotikMessage={setRobbotikMessage}
                        />}
                        {(((careReceipients.levelOfCare !== '0' && careReceipients.levelOfCare !== '1' && authorizedPerson.periodOfCare !=='Unter 6 monate' && authorizedPerson.professionalCarer !== 'Ja' )
                           && (authorizedPerson.durationOfAbsence && quizAuth.length !== indexAuth))
                         || ( (careReceipients.levelOfCare !== '0' && careReceipients.levelOfCare !== '1' && authorizedPerson.periodOfCare !=='Unter 6 monate' && authorizedPerson.professionalCarer !== 'Ja')
                                    && (authorizedPerson.durationOfAbsence && quizAuth.length === indexAuth))) &&
                        <RepresentativeChallenge
                            quizRep={quizRep}
                            notRepAnsweredIndex={notRepAnsweredIndex}
                            editList={editList}
                            setEdit={setEdit}
                            getTextValue={getTextValue}
                            handleRepTextAnswer={handleRepTextAnswer}
                            edit={edit}
                            handleEdit={handleEditRep}
                            handleCancel={handleCancelRep}
                            messageRefs={messageRefs}
                            robbotikMessage={robbotikMessage}
                            setRobbotikMessage={setRobbotikMessage}
                        />}
                        {((careReceipients.levelOfCare !== '0' && careReceipients.levelOfCare !== '1') &&
                                ((representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') && quizRep.length === indexRep)) &&
                             <CommerciallyActiveChallenge
                                sumActive={Active}
                                quizActive={quizActive}
                                indexActive={indexActive}
                                notActiveAnsweredIndex={notActiveAnsweredIndex}
                                editList={editList}
                                setEdit={setEdit}
                                getTextValue={getTextValue}
                                handleActiveTextAnswer={handleActiveTextAnswer}
                                edit={edit}
                                handleEdit={handleEditActive}
                                handleCancel={handleCancelActive}
                                messageRefs={messageRefs}
                                robbotikMessage={robbotikMessage}
                                setRobbotikMessage={setRobbotikMessage}
                            />
                        }
                        {((careReceipients.levelOfCare !== '0' && careReceipients.levelOfCare !== '1' ) && (
                                (representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Ja' && representativePerson.relationship) ||
                                (representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Nein' && representativePerson.relationship) ||
                                (representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Nein' && representativePerson.relationship) || (
                                    representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja' &&
                                    representationByCommerciallyActive.respiteCare1Remuneration
                                )
                            )) && <PrivateIndividualChallenge
                                sumPrivate={Private}
                                quizPrivate={quizPrivate}
                                indexPrivate={indexPrivate}
                                notAPrivateAnsweredIndex={notPrivateAnsweredIndex}
                                editList={editList}
                                setEdit={setEdit}
                                getTextValue={getTextValue}
                                handlePrivateTextAnswer={handlePrivateTextAnswer}
                                edit={edit}
                                handleEdit={handleEditPrivate}
                                handleCancel={handleCancelPrivate}
                                messageRefs={messageRefs}
                                robbotikMessage={robbotikMessage}
                                setRobbotikMessage={setRobbotikMessage}
                            />}
                    {representationByPrivateIndividual.signatureOfThePerson &&  <li className={styles.finalQuestion}>
                        <img src={finalQuestions?.img} alt={finalQuestions?.img}/>
                        <p>
                            {finalQuestions?.question}
                        </p>
                    </li>}
                    </ul>
                    <div ref={bottomOfPanelRef}></div>
                </div>

            </div>
            <div>
                <div>
                    <img src={Robotik} alt={Robotik}/>
                </div>
            </div>
            {(windowWidth.width < 768 && pathname === '/startChallenge' && (
                    !isDisabledCare &&
                    !isDisabledLegal &&
                    !isDisabledAuth &&
                    !isDisabledRep &&
                    !isDisabledActive &&
                    !isDisabledPrivate
                ))
                &&
                <button onClick={handleSkip}>
                    <p>
                        Überspringen
                    </p>
                </button>}
        </div>
        </PageComponent>
    )
}
export default ChallengeSteps
