import React, { useRef, useState } from 'react'
import styles from './buttonDropDown.module.scss'
import Arrow from '../../../icons/arrow.svg'
import PickList from '../PickList'
import OutsideClickHandler from 'react-outside-click-handler'

const ButtonDropDown = ({ value, options, onChange }) => {
  const [coords, setCoords] = useState({ left: 0, top: 0 })
  const [pickListOpened, setPickListOpened] = useState(false)
  const buttonDropDownRef = useRef(null)
  const pickListNode = document.getElementById('pickList')

  const handleButtonClick = () => {
    const rect = buttonDropDownRef.current.getBoundingClientRect()
    setCoords({
      left: rect.x,
      top: rect.y + rect.height + 8,
      minWidth: rect.width,
    })
    setPickListOpened(!pickListOpened)
  }

  const handleItemClick = (newValue) => {
      onChange(newValue)
    setPickListOpened(false)
  }

  return (
    <OutsideClickHandler
      onOutsideClick={(e) => {
        if (pickListOpened && pickListNode.contains(e.target)) {
          return
        }
        e.stopPropagation()
        e.preventDefault()
        setPickListOpened(false)
      }}
    >
      <button
        className={styles.buttonStyles}
        // onClick={handleButtonClick}
        ref={buttonDropDownRef}
      >
        {value} <img src={Arrow} alt={Arrow}/>
      </button>
      {pickListOpened && (
        <PickList
          options={options}
          handleItemClick={handleItemClick}
          selectedValue={value}
          coords={coords}
        />
      )}
    </OutsideClickHandler>
  )
}

export default ButtonDropDown
