import React, { useEffect } from 'react'
import styles from './toastNotification.module.scss'
import { useDispatch } from 'react-redux'
import { closeToastNotification } from '../../../redux/actions/toastNotification'
import { ToastIcons } from '../../../constant/constants'
import CloseIcon from '../../../icons/close.svg'

const ToastNotification = ({ toastTittle, toastBody, type }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        setTimeout(() => {
            dispatch(closeToastNotification())
        }, 5000)
    }, [])

    return (
        <div className={styles.toastNotification}>
            <img src={ToastIcons[type]} alt={'Toast Icon'} />
            <div className={styles.text}>
                <span className={styles.tittle}>{toastTittle}</span>
                {toastBody ? <span className={styles.body}>{toastBody}</span> : []}
            </div>
            <img
                className={styles.closeButton}
                src={CloseIcon}
                alt={'Close Icon'}
                onClick={() => dispatch(closeToastNotification())}
            />
        </div>
    )
}
export default ToastNotification
