import {apiGet, apiPut} from "./api";

export const getUserData = () => {
    return apiGet(`api/v1/reimbursement-accounts/me`)
}

export const upDateUserData = (body) => {
    return apiPut(`api/v1/reimbursement-accounts/me`, body)
}

export const getReimbursements = (ref) =>{
    return apiGet(`api/v1/reimbursements/${ref}`)
}


export const upDateUserPassword = (body) => {
    return apiPut(`api/v1/reimbursement-accounts/me/change-password`, body)
}



