import React, {useEffect, useRef, useState} from "react";
import styles from '../../../Pages/Common/Footer/footer.module.scss'
import {useDispatch, useSelector} from "react-redux";
import Euro from '../../../../icons/Euro.svg'
import {setCommercially} from "../../../../redux/actions/careReceipients";
import {setCommerciallyActive, setPrivateIndividual} from "../../../../redux/actions/quizQuestions";
import CalendarIcon from "../../../../icons/calendar.svg";
import Calendar from "react-calendar";
import moment from "moment";
import RightArrow from "../../../../icons/rightArrowIcon.svg";
import LeftArrow from "../../../../icons/leftArrowIcon.svg";
import ArrowBtn from "../../../../icons/arrowBtn.svg";
import cx from "classnames";
const QuizCommerciallyActive = () => {
    const { commerciallyActive:{quizActive,indexActive} } = useSelector((state) => state.quizQuestions)
    const { representationByCommerciallyActive } = useSelector((state) => state)
    const dispatch = useDispatch()
    const { selectedLanguage } = useSelector((state) => state.metadata)
    const [answerActive, setAnswerActive] = useState('')
    const [showCalendar, setShowCalendar] = useState(false)
    const wrapper = useRef(null);

    let sum = Number(representationByCommerciallyActive.respiteCare2Remuneration) + Number(representationByCommerciallyActive.respiteCare1Remuneration)
    useEffect(() => {
        if(indexActive === 8) {
            const newQuiz= quizActive.map((item , i)=> {
                if(i === indexActive){
                    return {
                        ...item,
                        answers: sum
                    }
                }

                return item
            })
            dispatch(setCommercially(Object.keys(representationByCommerciallyActive)[indexActive], sum))
            dispatch(setCommerciallyActive(newQuiz,indexActive + 1))
        }
    }, [indexActive]);
    useEffect(() => {
        document.addEventListener("click", handleClickOut, false);
        return () => {
            document.removeEventListener("click", handleClickOut, false);
        };
    }, []);
    const handleClickOut = event => {
        if (wrapper.current && !wrapper.current.contains(event.target)) {
            setShowCalendar(false)
        }
    };
    const handleChange = (e) => {
        setAnswerActive(e.currentTarget.value)
    }
    const handleActiveAnswer = () => {
        if(!answerActive) return
        const newQuiz= quizActive.map((item , i)=> {
            if(i === indexActive){
                return {
                    ...item,
                    answers: item.answers === 'sum' ? sum : answerActive
                }
            }

            return item
        })
        dispatch(setCommercially(Object.keys(representationByCommerciallyActive)[indexActive], answerActive))
        dispatch(setCommerciallyActive(newQuiz,indexActive + 1))
        setAnswerActive('')
    }
    const  handleChangeActiveCalendar = (e) =>{
        setAnswerActive(moment(e).format('DD.MM.YYYY'))
        setShowCalendar(false)
    }
    const convertToDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        const date = new Date(year, month - 1, day);
        return date.toString();
    }
    // console.log(convertToDate(answerActive));
    const handleShowActiveCalendar = () => {
        if(!showCalendar) {
            setShowCalendar(true)
        }else{
            setShowCalendar(false)
        }
    }
    return (
        <>
            {quizActive?.map((item,i)=>{
                if(i === indexActive) {
                    switch (item?.type) {
                        case 'date':
                            return (<div>
                                <input placeholder="Type date" value={!answerActive ?
                                    moment(new Date(moment().year(), moment().month(), moment().date())).format('DD.MM.YYYY')
                                    : moment(convertToDate(answerActive)).format('DD.MM.YYYY')} required
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') handleActiveAnswer()}} key={i} autoFocus={true} onClick={handleShowActiveCalendar} />
                                <img src={CalendarIcon} alt={CalendarIcon} onClick={handleShowActiveCalendar}/>
                                {!!showCalendar && <Calendar
                                    onChange={(e) => handleChangeActiveCalendar(e)}
                                    value={!answerActive ? new Date(moment().year(),moment().month(),moment().date()) : convertToDate(answerActive)}
                                    className={styles.reactCalendar}
                                    nextLabel={<img src={RightArrow} alt={'next'}/>}
                                    prevLabel={<img src={LeftArrow} alt={'Previous'}/>}
                                    next2Label={null}
                                    prev2Label={null}
                                    locale={'de'}
                                />}
                            </div>)
                        case 'time' :
                            return (
                                <input id="appt-time" type="time" name="appt-time" required
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') handleActiveAnswer()}} key={i}
                                       onChange={(e)=>handleChange(e)} autoFocus={true}/>
                            )
                        case 'number':
                            return  <input type={'number'} pattern="" placeholder={'Geben Sie eine Antwort ein.'} value={answerActive}
                                           onChange={(e)=>handleChange(e)} onInput={(e) =>
                                e.currentTarget.value = e.currentTarget.value.slice(0, 5)} onKeyDown={(e) => {
                                if (e.key === 'Enter') handleActiveAnswer()}} key={i} autoFocus={true}/>
                        case 'integer':
                            return (
                                <div>
                                    <input type={'number'} pattern="" placeholder={'Geben Sie eine Antwort ein.'} value={answerActive}
                                           onChange={(e)=>handleChange(e)}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') handleActiveAnswer()}} key={i} autoFocus={true}/>
                                    <img src={Euro} alt={Euro} />
                                </div>
                                )
                    }
                }
            })
            }
            {!representationByCommerciallyActive.respiteCare1Remuneration && <button disabled={!answerActive} className={cx({ [styles.btnDisabled]: !answerActive })} onClick={handleActiveAnswer}>
                <img src={ArrowBtn} alt={ArrowBtn} />
            </button>}

        </>
    )
}
export default QuizCommerciallyActive
