import React, {useState} from "react";
import ArrowBtn from "../../../../icons/arrowBtn.svg";
import {useDispatch, useSelector} from "react-redux";
import {setLegalRepresentations} from "../../../../redux/actions/careReceipients";
import {setLegalRepresentation} from "../../../../redux/actions/quizQuestions";
import styles from "../../../Pages/Common/Footer/footer.module.scss";
import AttachFile from "../../../../icons/attach.svg";
import cx from "classnames";
import {uploadFile} from "../../../../api/formApi";
import {handlePhoneNumberFieldChange} from "../../../../redux/actions/Auth";
import PhoneNumberField from "../../../ReusableField/PhoneNumberField";

const QuizLegalRepresentation = () => {
    const [answer, setAnswer] = useState('')
    const [fileName, setFileName] = useState('')
    const { quizLegalRepresentation:{quizLegal,indexLegal} } = useSelector((state) => state.quizQuestions)
    const { legalRepresentations, payment:{reimbursementRef} } = useSelector((state) => state)
    const { phoneNumberObj } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const handleChange = (e) => {
        setAnswer(e.currentTarget.value)
    }
    const handleFile = (e) =>  {
        const file = e.target.files[0]
        let formData = new FormData();
        formData.append('file', file);
        // const payload = {
        //     file: formData
        // }
        uploadFile(formData,reimbursementRef,'RC_LEGAL_REPRESENTATION')
            .then((res)=> {
                console.log(res)
                setAnswer(res.amazonUrl)
            })
            .catch((res)=> console.log(res))
        setFileName(file?.name)
    }
    const handleLegalAnswer = () => {
        if(!answer) return
        const newQuiz= quizLegal.map((item , i)=> {
            if(i === indexLegal){
                return {
                    ...item,
                    answers: answer,
                    fileName: fileName
                }
            }
            return item
        })
        dispatch(setLegalRepresentations(Object.keys(legalRepresentations)[indexLegal],answer))
        dispatch(setLegalRepresentation(newQuiz,indexLegal + 1))
        dispatch(handlePhoneNumberFieldChange({...phoneNumberObj,number:''}))
        setAnswer('')
    }
    const handleChangePhone = (newPhoneNumberObj) => {
        dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
    }
    return (
            <>
                {quizLegal.map((item,i)=>{
                    if(i === indexLegal) {
                        switch (item?.type) {
                            case 'text':
                                return  <input type={'text'} pattern="" placeholder={'Geben Sie eine Antwort ein.'} value={answer}
                                               onChange={(e)=>handleChange(e)}
                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') handleLegalAnswer()}} key={i} autoFocus={true}/>
                            case 'phoneNumber':
                                return <PhoneNumberField
                                    onChange={handleChangePhone}
                                    phoneNumberObj={phoneNumberObj}
                                    setAnswerr={setAnswer}
                                />
                            case 'number':
                                return  <input type={'number'} pattern="" placeholder={'Geben Sie eine Antwort ein.'} value={answer}
                                               onChange={(e)=>handleChange(e)} onInput={(e) =>
                                    e.currentTarget.value = e.currentTarget.value.slice(0, 5)} onKeyDown={(e) => {
                                    if (e.key === 'Enter') handleLegalAnswer()}} key={i} autoFocus={true}/>
                            case 'file':
                                return  <>
                                    <span className={styles.imageUpload}>
                                        <label htmlFor="file-input">
                                            <img src={AttachFile} alt={AttachFile} />
                                        </label>
                                        <input
                                            id="file-input"
                                            type="file"
                                            onChange={(e)=> handleFile(e)}
                                            accept="image/jpeg,image/png,.pdf"
                                        />
                                    </span>
                                    <input type={'text'} placeholder={'Attach a file'} value={fileName} className={styles.attach}/>
                                </>
                        }
                    }
                })}
                {/*{quizLegal.length === indexLegal && <input type={'text'} />}*/}
                {!legalRepresentations.document  && <button disabled={!answer} className={cx({ [styles.btnDisabled]: !answer })} onClick={handleLegalAnswer}>
                    <img src={ArrowBtn} alt={ArrowBtn} />
                </button>}
            </>
    )
}
export default QuizLegalRepresentation
