import React, {useEffect} from "react";
import styles from './footer.module.scss'
import {useDispatch, useSelector} from "react-redux";
import cx from 'classnames'
import Skip from '../../../../icons/skipIcon.svg'
import {
    setAuthPerson,
    setCareQuizQuestions, setCommerciallyActive, setFormEdit,
    setLegalRepresentation, setPrivateIndividual,
    setRepresentativePerson
} from '../../../../redux/actions/quizQuestions';
import {
    setAuthorizedPerson,
    setCareReceipients, setCommercially, setIndividual,
    setLegalRepresentations, setRepresentative
} from "../../../../redux/actions/careReceipients";
import QuizCareReceipients from "../../../NestedComponents/Option 1/QuizCareReceipients";
import QuizLegalRepresentation from "../../../NestedComponents/Option 1/QuizLegalRepresentation";
import { createForm, createAuthUserForm, submitAuthUserForm, submitReimbursement } from '../../../../api/formApi';
import { setReimbursementRef } from '../../../../redux/actions/payment';
import AuthorizePerson from "../../../NestedComponents/Option 1/AuthorizePerson";
import QuizRepresentative from "../../../NestedComponents/Option 1/QuizRepresentative";
import QuizCommerciallyActive from "../../../NestedComponents/Option 1/QuizCommerciallyActive";
import QuizPrivateIndividual from "../../../NestedComponents/Option 1/QuizPrivateIndividual";
import {useNavigate} from "react-router-dom";
const Footer = () => {
    const navigate = useNavigate()
    const { pathname } = window.location
    const {
        careReceipients ,
        legalRepresentations ,
        authorizedPerson,
        representativePerson,
        representationByCommerciallyActive,
        representationByPrivateIndividual,
        payment :{reimbursementRef}
    } = useSelector((state) => state)
    const email = useSelector((state) => state.userDetails).email
    const { quizCareReceipients:{quizCare,indexCare},
        quizLegalRepresentation:{quizLegal, indexLegal},
        quizAuthorizedPerson:{quizAuth,indexAuth},
        quizRepresentativePerson:{quizRep,indexRep},
        commerciallyActive:{quizActive,indexActive},
        privateIndividual:{quizPrivate,indexPrivate},
        chooseCard,
        editSummary,
        formEdit
    } = useSelector((state) => state.quizQuestions)
    const dispatch = useDispatch()

    const isDisabledCare = quizCare.some((item, i) => i === indexCare && item.required === true)
    const isDisabledLegal = quizLegal.some((item, i) => i === indexLegal && item.required === true)
    const isDisabledAuth = quizAuth.some((item, i) => i === indexAuth && item.required === true)
    const isDisabledRep = quizRep.some((item, i) => i === indexRep && item.required === true)
    const isDisabledActive = quizActive.some((item, i) => i === indexActive && item.required === true)
    const isDisabledPrivate = quizPrivate.some((item, i) => i === indexPrivate && item.required === true)
    useEffect(() => {
    }, [careReceipients,
        legalRepresentations,
        authorizedPerson,
        representativePerson,
        representationByCommerciallyActive,
        representationByPrivateIndividual]);

    const noLegal = {
        name: '',
        lastName: '',
        address: '',
        houseNumber: '',
        zipCode : '',
        city : '',
        phoneNumber: '',
        document: '',
    }
    const noActive = {
        respiteCare1Date: '',
        respiteCare1Time: '',
        respiteCare1Duration: '',
        respiteCare1Remuneration: '',
    }
    const handleSkip = () => {
        if(quizCare.length !== indexCare) {
            const newCareQuiz= quizCare.map((item , i)=> {
                if(i === indexCare){
                    return {
                        ...item,
                        answers: '-',
                        fileName: '-'
                    }

                }
                return item
            })
            dispatch(setCareReceipients(Object.keys(careReceipients)[indexCare],'-'))
            dispatch(setCareQuizQuestions(newCareQuiz,indexCare+1))
        }else{
            if(careReceipients.legalRepresentation === 'Ja' && quizLegal.length !== indexLegal){
                const newLegalQuiz = quizLegal.map((item , i)=> {
                    if(i === indexLegal){
                        return {
                            ...item,
                            answers: '-',
                            fileName: '-'

                        }
                    }
                    return item
                })
                dispatch(setLegalRepresentations(Object.keys(legalRepresentations)[indexLegal],'-'))
                dispatch(setLegalRepresentation(newLegalQuiz,indexLegal+1))
            }else{
                if(quizAuth.length !== indexAuth ){
                    const newAuthQuiz = quizAuth.map((item , i)=> {
                        if(i === indexAuth){
                            return {
                                ...item,
                                answers: '-'
                            }
                        }
                        return item
                    })
                    dispatch(setAuthorizedPerson(Object.keys(authorizedPerson)[indexAuth],'-'))
                    dispatch(setAuthPerson(newAuthQuiz,indexAuth+1))
                }else {
                    if(quizRep.length !== indexRep) {
                        const newRepQuiz = quizRep.map((item , i)=> {
                            if(i === indexRep){
                                return {
                                    ...item,
                                    answers: '-'
                                }
                            }
                            return item
                        })
                        dispatch(setRepresentative(Object.keys(representativePerson)[indexRep],'-'))
                        dispatch(setRepresentativePerson(newRepQuiz,indexRep+1))
                    }else{
                        if((representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') && quizActive.length !== indexActive) {
                            const newActiveQuiz = quizActive.map((item , i)=> {
                                if(i === indexActive){
                                    return {
                                        ...item,
                                        answers: '-'
                                    }
                                }
                                return item
                            })
                            dispatch(setCommercially(Object.keys(representationByCommerciallyActive)[indexActive],'-'))
                            dispatch(setCommerciallyActive(newActiveQuiz,indexActive+1))
                        }else{
                            if(((representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Ja' && representativePerson.relationship) ||
                                (representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Nein'&& representativePerson.relationship) ||
                                (representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Nein' && representativePerson.relationship) || (
                                    representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja' &&
                                    representationByCommerciallyActive.respiteCare1Remuneration
                                )
                            ) && quizPrivate.length !== indexPrivate) {
                                const newPrivateQuiz = quizPrivate.map((item , i)=> {
                                    if(i === indexPrivate){
                                        return {
                                            ...item,
                                            answers: '-'
                                        }
                                    }
                                    return item
                                })
                                dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[indexPrivate],'-'))
                                dispatch(setPrivateIndividual(newPrivateQuiz,indexPrivate + 1))
                            }
                        }
                    }
                }
            }
        }


    }
    const handleSubmit = () =>{
        let requestBody = {
            email: email,
            params:{
                careRecipients: careReceipients,
                legalRepresentations: careReceipients.legalRepresentation === 'Nein' ? noLegal : legalRepresentations,
                authorizedPerson:authorizedPerson,
                representativePerson:representativePerson,
                representationByCommerciallyActive: (representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') ? representationByCommerciallyActive : noActive,
                representationByPrivateIndividual:representationByPrivateIndividual
            }
        }
        if(formEdit) {
            requestBody.ref = reimbursementRef
        }
        if (localStorage.getItem('accessToken')) {
            createAuthUserForm(requestBody)
                .then((res)=> {
                    dispatch(setReimbursementRef(res.ref))
                    dispatch(setFormEdit(false))
                    return res.ref
                })
                .then((ref) => {
                    submitAuthUserForm({ ref })
                        .then(() =>{
                            // chooseCard === 'basic' ? navigate('/summary') : navigate('/payment')
                            navigate('/summary')
                        })
                        .catch((err) => console.log(err))
                })
            return
        }
        createForm({...requestBody, option: chooseCard === 'basic' ? 1 : chooseCard === 'pro' ? 2 : 3})
            .then((res)=> {
             if(chooseCard === 'basic'){
                 const requestBody = {
                     ref: res.ref,
                     email: res.email,
                     option: 1,
                 }
                 submitReimbursement(requestBody)
                     .then(() => {})
                     .catch((err) => console.log(err))
             }
            dispatch(setFormEdit(false))
            dispatch(setReimbursementRef(res.ref))
            })
            .then(() => {
                chooseCard === 'basic'  ||  editSummary ? navigate('/summary') : navigate('/payment')
            })
    }

    return(

        <div className={cx(styles.footer,{
            [styles.challengeStep]: pathname === '/startChallenge',
        })}>
            {(representationByPrivateIndividual.date)  && pathname === '/startChallenge'?
                <button className={cx(styles.submit,
                    {[styles.disabledBox]: representationByPrivateIndividual.date && (representationByPrivateIndividual.iAgree === '' || representationByPrivateIndividual.iHave === '') || editSummary})}
                        onClick={handleSubmit} disabled={representationByPrivateIndividual.date && (representationByPrivateIndividual.iAgree === '' || representationByPrivateIndividual.iHave === '') || editSummary}>
                    Submit</button> :
                pathname === '/startChallenge' ?
                    <div className={cx(styles.inputFooter,{
                        [styles.required]: isDisabledCare || isDisabledLegal || isDisabledAuth || isDisabledRep || isDisabledActive || isDisabledPrivate
                    })}>
                        <div>
                            <QuizCareReceipients/>
                            {careReceipients.legalRepresentation === 'Ja' && quizCare.length === indexCare  && <QuizLegalRepresentation/>}
                            {((careReceipients.legalRepresentation === 'Ja' && quizLegal.length === indexLegal) ||
                                (careReceipients.legalRepresentation === 'Nein' && quizCare.length === indexCare))  && <AuthorizePerson/>}
                            {(authorizedPerson.durationOfAbsence && quizAuth.length === indexAuth) && <QuizRepresentative/>}
                            {/*IF 56 and/or 57 YES:*/}
                            { ((representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') && quizRep.length === indexRep) &&
                                <QuizCommerciallyActive />}
                            {/*IF 56, 57, 60 YES, and 58, 59 NOT UNRELATED*/}
                            {((representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Ja' && representativePerson.relationship) ||
                                    (representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Nein'&& representativePerson.relationship) ||
                                    (representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Nein' && representativePerson.relationship) || (
                                        representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja' &&
                                        representationByCommerciallyActive.respiteCare1Remuneration
                                    )
                                )
                                &&
                            <QuizPrivateIndividual/>}
                        </div>
                        {(pathname === '/startChallenge' && (
                                !isDisabledCare &&
                                !isDisabledLegal &&
                                !isDisabledAuth &&
                                !isDisabledRep &&
                                !isDisabledActive &&
                                !isDisabledPrivate
                            ))
                            &&
                            <button onClick={handleSkip}>
                                <p>
                                    Überspringen
                                </p>
                            </button>}
                    </div> :
                    <div>
                        <p>
                            © 2024 FLEXXI CARE - Alle Rechte vorbehalten
                        </p>
                    </div>
            }
        </div>
    )
}
export default Footer
