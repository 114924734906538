import {
  SET_SELECTED_LANGUAGE,
} from '../../ActionTypes'


export const setSelectedLanguage = (selectedLanguage) => ({
  type: SET_SELECTED_LANGUAGE,
  selectedLanguage,
})

