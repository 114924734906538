import {
  CLOSE_CONFIRMATION_MODAL,
  SET_CONFIRMATION_MODAL
} from '../../ActionTypes'

export const setConfirmationModal = (modalProps) => ({
  type: SET_CONFIRMATION_MODAL,
  modalProps,
})

export const closeConfirmationModal = () => ({
  type: CLOSE_CONFIRMATION_MODAL,
})

