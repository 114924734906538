import {careReceipients} from "../../defaultStates";
import {SET_CARE_RECEIPIENTS, SET_EMPTY_CARE, SET_UPDATE_CARE_RECEIPIENTS,} from "../../ActionTypes";

export default (state = careReceipients , action = {}) => {

    switch (action.type) {
        case SET_CARE_RECEIPIENTS:
            return {
                ...state,
                [action.key]: action.value,
            }
        case SET_UPDATE_CARE_RECEIPIENTS:
            return {
                ...action.careReceipients
            }
        case SET_EMPTY_CARE:
            return {
                ... action.care
            }
        default:
            return state

    }
}
