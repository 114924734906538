import {
  SET_CONFIRMATION_MODAL,
  CLOSE_CONFIRMATION_MODAL,
} from '../../ActionTypes'

export default (state = null, action = {}) => {
  switch (action.type) {
    case SET_CONFIRMATION_MODAL:
      return {
        ...action.modalProps,
      }
    case CLOSE_CONFIRMATION_MODAL:
      return null
    default:
      return state
  }
}
