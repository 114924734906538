import {PHONE_NUMBER_FIELD_CHANGE} from '../../ActionTypes'


const handlePhoneNumberFieldChange = (phoneNumberObj) => ({
  type: PHONE_NUMBER_FIELD_CHANGE,
  phoneNumberObj,
})


export {
  handlePhoneNumberFieldChange,
}
