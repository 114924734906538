import { apiPost } from './api';

export const refreshToken = (refreshToken) => {
    return apiPost('api/v1/reimbursement-accounts/refresh-token', { refreshToken: refreshToken })
}
export const startAccountRobot = (body) => {
    return apiPost(`api/v1/reimbursements`, body)
}
export const creatAccount = (body) => {
    return apiPost(`api/v1/reimbursement-accounts`,body)
}
export const login = (body) => {
    return apiPost(`api/v1/reimbursement-accounts/login`, body)
}
