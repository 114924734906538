const relationshipList = [
    'nicht verwandt',
    'Ehepartner',
    'Verwandt 1 Grad: Vater, Mutter, Sohn oder, Tochter des Berechtigten',
    'Verwandt 2. Grades: Schwester, Bruder, Grossmutter, Grossvater, Enkeltochter oder Enkelsohn des Berechtigten',
    'eingetragener Lebenspartner',
    'andere Verwandtschaft'
]

const reasonsOfAbsence =[
    'Ruhetag (zB wegen Erschöpfung, religiöser Feiertag)',
    'Erhlolungsurlaub',
    'Krankenhaus-/Reha/Kuraufenthalt',
    'Dienstreise',
    'eigene längere Krankheit',
    'Krankheit einer nahestehenden Person',
    'eigene kurzfristige Krankheit',
    'dienstliche Verpflichtung',
    "Arztbesuch",
    'Private Abwesenheit (Theaterbesuch, Familienfeier, Frisör) mit positiver Wirkung auf das eigene Allgemeinbefinden oder das einer dritten Person',
    'sonstige, moralisch erforderliche Abwesenheit, die einem Dritten oder der Pflegenden Person zur Hilfe dient',
    'Besuch einer Fortbildung'
]

const relationshipByMarriageList = [
    'Nicht verschwägert',
    'Verschwägert 1a) Ehepartner / Lebenspartner von Vater Mutter Sohn Tochter des Berechtigten',
    'Verschwägert 1b) Vater Mutter Sohn Tochter des Ehepartner / Lebenspartners des Berechtigten',
    'Verschwägert 2a) Ehepartner von Schwester Bruder Grossmutter Grossvater Enkeltochter Enkelsohn des Berechtigten',
    'Verschwägert 2b) Schwester Bruder Grossmutter Grossvater Enkeltochter Enkelsohn des Ehepartnes / Lebenspartners des Berechtigten',
    'Anders verwandt oder verschwägert'
]

const otherRelationshipList = [
    'durch Dritte vermittelte Pflege, zB FLEXXI Pflegekraft',
    'Pflegefirma',
    'Nachbar, im selben Haus lebend, nicht verwandt oder verschwägert',
    'Anderer Bekannter oder Nachbar'
]

const insuranceCompanies =[
    'KNAPPSCHAFT',
    'Koenig & Bauer BKK',
    'Krones Betriebskrankenkasse',
    'Mercedes-Benz BKK',
    'Merck BKK',
    'mhplus Betriebskrankenkasse',
    'Novitas BKK',
    'Pronova BKK',
    'R+V Betriebskrankenkasse',
    'Salus BKK',
    'SECURVITA BKK',
    'Siemens-Betriebskrankenkasse (SBK)',
    'SKD BKK',
    'Sozialversicherung für Landwirtschaft, Forsten und Gartenbau (SVLFG)',
    'Südzucker BKK',
    'Techniker Krankenkasse',
    'TUI BKK',
    'VIACTIV Krankenkasse',
    'vivida bkk',
    'WMF Betriebskrankenkasse',
    'Allianz Private Krankenversicherungs-AG',
    'ALTE OLDENBURGER Krankenversicherung von 1927 V.V.a.G',
    'Arag ARAG Krankenversicherungs-AG',
    'Astra Versicherung AG',
    'AXA Krankenversicherung',
    'BA die Bayerische Allgemeine Versicherung AG',
    'Barmenia Krankenversicherung AG',
    'Bayerische Beamtenkrankenkasse Aktiengesellschaft',
    'CONCORDIA Krankenversicherungs-Aktiengesellschaft',
    'Continentale Krankenversicherung a.G.',
    'DA direkt Deutsche Allgemeine Versicherung AG',
    'Debeka Krankenversicherungsverein auf Gegenseitigkeit',
    'DEVK Krankenversicherungs-Aktiengesellschaft',
    'DFV Deutsche Familienversicherung AG',
    'DKV Deutsche Krankenversicherung AG',
    'ENVIVAS Krankenversicherung AG',
    'ERGO Krankenversicherung AG',
    'Europ Assistance SA',
    'EUROPA Versicherung AG',
    'FREIE ARZT- UND MEDIZINKASSE der Angehörigen der  und der Polizei VVaG',
    'Generali Deutschland Krankenversicherung AG',
    'GOTHAER KRANKENVERSICHERUNG AG',
    'HALLESCHE Krankenversicherung auf Gegenseitigkeit',
    'HanseMerkur Krankenversicherung AG',
    'HanseMerkur Speziale Krankenversicherung AG',
    'HUK-COBURG Krankenversicherung AG',
    'IDEAL Versicherung' ,
    'INTER Krankenversicherung AG',
    'Janitos Versicherung AG',
    'KUK Krankenunterstützungskasse Hannover (KUK Hannover)',
    'KVB Krankenversorgung der Bundesbahnbeamten',
    'Landeskrankenhilfe  V.V.a.G.',
    'LIGA Krankenversicherung katholischer Priester VVaG',
    'LVM Krankenversicherungs-AG',
    'Mecklenburgische Krankenversicherungs-AG',
    'Münchener Verein Versicherungsgruppe',
    'NÜRNBERGER Krankenversicherung AG',
    'ottonova Krankenversicherung AG',
    'PBeaKK Postbeamtenkrankenkasse Körperschaft des öffentlichen Rechts (KdöR)',
    'Provinzial Krankenversicherung Hannover AG',
    'R + V Krankenversicherung AG',
    'SDK Süddeutsche Krankenversicherung a.G.',
    'SIGNAL IDUNA Krankenversicherung a.G.',
    'SONO Krankenversicherung a.G',
    'St. Martinus Priesterverein der Diözese Rottenburg-Stuttgart – Kranken- und Sterbekasse (KSK) – VVaG',
    'Union Krankenversicherung AG',
    'uniVersa Krankenversicherung a.G.',
    'Versicherer im Raum der Kirchen  Krankenversicherung AG',
    'vigo Krankenversicherung VVaG',
    'WGV WGV-Versicherung AG',
    'Württembergische Krankenversicherung AG',
    'Würzburger Versicherungs-AG',
]

export {
    relationshipList,
    reasonsOfAbsence,
    relationshipByMarriageList,
    otherRelationshipList,
    insuranceCompanies
}
