import React, { useEffect, useRef, useState } from 'react'
import styles from './input.module.scss'
import cx from 'classnames'
import EyeIcon from '../../../icons/eye.svg'
import EyeShowIcon from '../../../icons/showEye.svg'
import EditIcon from '../../../icons/edit.svg'
import ErrorIcon from '../../../icons/errorIcon.svg'

const Input = ({
  placeholder,
  marginBottom,
  type,
  showEye,
  tip,
  calendar,
  autoFocus,
  validationMessage,
  required,
  readOnly,
  promoCodeApplied,
  handleApplyOrEditPromoCode,
  values,
  setValues,
  edit,
  onEditClick,
  passwordEdit,
  ...props
}) => {
  const [typeState, setTypeState] = useState(type || 'text')
  const [showIcon, setShowIcon] = useState(false)
  const inputRef = useRef(null)

  const onEyeClick = () => {
    if (typeState === 'password') {
      setTypeState('text')
      setShowIcon(true)
    } else {
      setTypeState('password')
      setShowIcon(false)
    }
  }

  useEffect(() => {
    if (autoFocus) {

      inputRef.current.focus()
    }
  }, [autoFocus])

  useEffect(() => {
    if(typeState === 'text' && type === 'password'){
      setTypeState(type)
      setShowIcon(false)
    }
  }, [passwordEdit])

  return (
    <div
      className={cx(styles.inputWrapper, {
        [styles.marginBottom]: marginBottom,
      })}
    >
      <input
        className={cx(styles.inputText, {
          [styles.invalidInput]: validationMessage,
          [styles.readOnly]: readOnly,
        })}
        type={typeState}
        {...props}
        required
        ref={inputRef}
      />
      <span
        className={cx(styles['floating-label'], {
          [styles.floatingLabelRequired]: required,
        })}
      >
        {placeholder}
      </span>
      {edit ?
          (<img src={EditIcon}
                alt={'Edit'}
                onClick={onEditClick}/>) :
          []
      }
      {showEye ? (
        <img
          src={showIcon ? EyeShowIcon : EyeIcon}
          alt={'Eye'}
          onClick={onEyeClick}
        />)  :
        []
      }
      {validationMessage ? (
          <div
              className={cx(styles.errorMessage, {
                [styles.worstPromoCode]: promoCodeApplied,
              })}
          >
            <img src={ErrorIcon} alt={ErrorIcon} />
            <span>{validationMessage}</span>
          </div>
      ) : null}

    </div>
  )
}

export default Input
