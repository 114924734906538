import SuccessIcon from '../icons/checkOutlined.svg'
import ErrorIcon from '../icons/errorIcon.svg'
import isProduction from '../utils/helpers/isProduction';

export const STRIPE_KEY = isProduction()
    ? 'pk_live_WNFteFfeZYrWAzQGceSSmDXD'
    : 'pk_test_Uy4a9tomQRukBXm14a5VfgME'

export const NUMBER_REGEXP = /^[0-9]+$/
export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const FULLNAME_REGEX = /^[a-zA-Z ]+$/

export const editQuiz = {
    careReceipients: 'quizCareReceipients',
    legalRepresentations: 'quizLegalRepresentation',
    authorizedPerson: 'quizAuthorizedPerson',
    representativePerson:'quizRepresentativePerson',
    representationByCommerciallyActive:'commerciallyActive',
    representationByPrivateIndividual:'privateIndividual'
}


export const ToastIcons = {
    success: SuccessIcon,
    error: ErrorIcon,
    // info: InfoIcon,
}

export const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export const leftNavigation = [
    {
        label: 'Settings',
        key: 'SETTINGS',
        // src: PersonalData,
        link: '/settings'
    },

]
