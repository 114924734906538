import React from 'react';
import styles from './chooseYourData.module.scss'
import Robotik from '../../../../icons/robbot.svg';
import MiniLogo from '../../../../icons/lightMiniLogo.svg'
import PurplleCheck from '../../../../icons/purplleCircle.svg'
import LightCheck from '../../../../icons/lightCircle.svg'
import {Link} from "react-router-dom";
import PageComponent from "../../../Pages/PageComponent";
import cx from "classnames";
import {useDispatch} from "react-redux";
import {setChooseCard} from "../../../../redux/actions/quizQuestions";
import isProduction from "../../../../utils/helpers/isProduction";


const ChooseYourData = () => {
    const dispatch = useDispatch()
    const handleChooseCard = (cardType) => {
        dispatch(setChooseCard(cardType))
    }


    return (
        <PageComponent>
            <div className={styles.chooseData}>
                <div className={styles.chooseCards}>
                    <div>
                        <div>
                            <img src={Robotik} alt={Robotik} />
                        </div>
                        <p>Choose the way you want to store your data</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Scelerisque ut id congue commodo. Semper malesuada morbi adipiscing tellus posuere.
                            Interdum.
                        </p>
                    </div>
                    <div className={styles.cards}>
                        <div className={styles.column}>
                            <div>
                                <div>
                                    <img src={MiniLogo} alt={MiniLogo} />
                                    <p>Basic</p>
                                </div>
                                <div className={styles.yearly}>€0</div>
                                <span className={styles.included}>What’s included</span>
                                <div className={styles.CheckLists}>
                                <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>All analytics features</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Up to 250,000 tracked visits</p>
                                </span>
                                </div>
                            </div>
                            <div className={styles.cardsBtn}>
                                <Link to={'/startChallenge'} state={ { from: '/chooseData' } }>
                                    <button onClick={() => handleChooseCard('basic')}>Start</button>
                                </Link>
                            </div>
                        </div>
                        <div className={styles.column}>
                            <div>
                                <div>
                                    <img src={MiniLogo} alt={MiniLogo} />
                                    <p>Pro</p>
                                    <span>Popular</span>
                                </div>
                                <div className={styles.yearly}>€18&nbsp;<span>/yearly</span></div>
                                <span className={styles.included}>What’s included</span>
                                <div className={styles.CheckLists}>
                                <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>All analytics features</p>
                                </span>
                                    <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Up to 1,000,000 tracked visits</p>
                                </span>
                                    <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Premium support</p>
                                </span>
                                    <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Up to 10 team members</p>
                                </span>
                                </div>
                            </div>
                            <div className={styles.cardsBtn}>
                                <Link to={'/startChallenge'} state={ { from: '/chooseData' } }>
                                    <button onClick={() => handleChooseCard('pro')}>Start</button>
                                </Link>
                            </div>
                        </div>
                        <div className={cx(styles.column)}>
                            <div>
                                <div>
                                    <img src={MiniLogo} alt={MiniLogo} />
                                    <p>Premium</p>
                                </div>
                                <div className={styles.yearly}>€41&nbsp;<span>/yearly</span></div>
                                <span className={styles.included}>What’s included</span>
                                <div className={styles.CheckLists}>
                                <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>All analytics features</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Up to 5,000,000 tracked visits</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Dedicated support</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Up to 50 team members</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Up to 50 team members</p>
                                </span>
                                </div>
                            </div>
                            <div className={cx(styles.cardsBtn,{[styles.disableBtn]: isProduction()})}>
                                <Link to={'/startChallenge'} state={ { from: '/chooseData' } }>
                                    <button disabled={isProduction()} onClick={() => handleChooseCard('premium')}>Subscribe</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Robotik} alt={Robotik} />
                </div>
            </div>
        </PageComponent>
    )
}
export default ChooseYourData
