import React, {useEffect} from 'react'
import  { useSelector} from 'react-redux'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import ToastNotification from '../../UiKitComponents/ToastNotification';
import ConfirmationModal from "../../ReusableField/ConfirmationModal";


const PageComponent = (props) => {

  const { careReceipients,authorizedPerson, representativePerson } = useSelector((state) => state)
  const confirmationModal = useSelector((state) => state.confirmationModal)
  const toastNotification = useSelector((state) => state.toastNotification)
  const { pathname } = window.location
  const footerNull = careReceipients.levelOfCare === '0' || careReceipients.levelOfCare === '1' || authorizedPerson.professionalCarer === 'Ja'
      || authorizedPerson.periodOfCare === 'Unter 6 monate' || representativePerson.relationshipList === 'nicht verwandt'
      || representativePerson.relationshipByMarriage === 'Nicht verschwägert' || pathname === '/chooseData' || pathname === '/summary' ||  pathname === '/setPassword' ||
      pathname === '/profile' || pathname === '/set-password' || pathname === '/settings'
  useEffect(() => {

  }, [careReceipients.levelOfCare]);
  return (
    <>
      <Header />
      {confirmationModal && <ConfirmationModal {...confirmationModal} />}
      {props.children}
        {footerNull ? null : <Footer/>}
        {(pathname === '/chooseData' || pathname === '/summary' ||  pathname === '/setPassword' || footerNull || pathname === '/profile' || pathname === '/settings') ? null : <Footer/>}
      {toastNotification ? <ToastNotification {...toastNotification} /> : []}
    </>
  )
}

export default PageComponent
