import React from 'react'
import styles from './phoneNumberField.module.scss'
import CountryPicker from '../CountryPicker'
import ErrorIcon from '../../../icons/errorIcon.svg'
import { useTranslation } from 'react-i18next'
import { NUMBER_REGEXP } from '../../../constant/constants'
import cx from 'classnames'

const PhoneNumberField = ({ phoneNumberObj, errorMessage, onChange, setAnswerr, value }) => {
  // console.log(phoneNumberObj);
  const { t } = useTranslation()
  const handleChangeInputValue = (e) => {
    if (NUMBER_REGEXP.test(e.currentTarget.value) || e.currentTarget.value === '') {
      const newPhoneNumberObj = {
        ...phoneNumberObj,
        number: e.currentTarget.value,
      }
      onChange(newPhoneNumberObj)
      setAnswerr(e.currentTarget.value)
    }
  }

  const handleChangeCountryCode = (countryCode, callingCode) => {
    const newPhoneNumberObj = {
      ...phoneNumberObj,
      countryCode: countryCode,
      callingCode: callingCode,
    }
    onChange(newPhoneNumberObj)
  }
  return (
    <div>
      <div
        className={cx(styles.phoneNumberField, {
          [styles.invalidInput]: errorMessage,
        })}
      >
        <CountryPicker
          countryCode={phoneNumberObj.countryCode}
          handleChangeCountryCode={handleChangeCountryCode}
        />
        <span> +{phoneNumberObj.callingCode} </span>
        <input
            placeholder={'12345678'}
            onChange={handleChangeInputValue}
            value={phoneNumberObj.number || value}
            autoFocus
            type={'number'}
        />
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>
          <img src={ErrorIcon} alt={ErrorIcon} />
          <span>{t(errorMessage)}</span>
        </div>
      )}
    </div>
  )
}

export default PhoneNumberField
