import React from 'react'
import Modal from '../../UiKitComponents/Modal'
import styles from './confirmationModal.module.scss'
import cx from 'classnames'
import ModalHeader from './ModalHeader'
import ModalFooter from './ModalFooter'
import ErrorMessageComponent from '../../ReusableField/ErrorMessageComponent'

const ConfirmationModal = ({
  headerText,
  body,
  confirm = {},
  cancel = {},
  cancelText,
  handleCancel,
  handleConfirm,
  handleBackStage,
  large,
  loading,
  warning,
  customFooter,
  stageCount,
  stage,
  form,
  modalError,
  hideCloseButton,
  checked,
}) => {
  const confirmDisabled = form && form?.isInValid
  return (
    <Modal>
      <section
        className={cx(styles.confirmationModal, {
          [styles.largeConfirmationModal]: large,
        })}
      >
        <ModalHeader
          text={headerText}
          stageCount={stageCount}
          stage={stage}
          handleCancel={handleCancel}
          handleBackStage={handleBackStage}
          warning={warning}
          hideCloseButton={hideCloseButton}
          checked={checked}
        />
        {body ? (
          <div
            className={cx(styles.bodyStyles, {
              [styles.bodyStylesForSmall]: !large || typeof body === 'string',
            })}
            id={'scrollingModal'}
          >
            {body}
          </div>
        ) : (
          []
        )}
        {!customFooter && modalError ? (
          <ErrorMessageComponent
            errorMessage={modalError}
            customFooter={customFooter}
          />
        ) : null}
        {!customFooter && (
          <ModalFooter
            confirm={confirm}
            cancel={cancel}
            cancelText={cancelText}
            loading={loading}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
            confirmDisabled={confirmDisabled}
          />
        )}
      </section>
    </Modal>
  )
}

export default ConfirmationModal
